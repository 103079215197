//- COLORS
$colorText = #333333;
$colorTextLight = #999999;
$colorAccent = #00B956;

$colorBorder = #E7EBF2;
$colorFill = #D7D7D7;

$colorSuccess = #15803d;
$colorError = #EF4444;


//- FONT
$font = -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
$fontRaleway = 'Raleway', $font;


//- VARS
$containerPadding = 15px;
$container = 1170px + 30;
$gridColumns = 12;
$gridPadding = 15px;


//- BREAKPOINTS
note = 'all and (max-width: 1759px)';
laptop = 'all and (max-width: 1399px)';
tablet = 'all and (max-width: 1279px)';
pad = 'all and (max-width: 1023px)';
phone = 'all and (max-width: 767px)';

$points = note laptop tablet pad phone;
$pointsPrefix = '-nt' '-lp' '-tb' '-pd' '-ph';


/*!
  BREAKPOINTS

  iPad Pro - 1366, 1024
  macbook old = 1280
  iPad - 1024, 768
  iPhone - 414, 375, 320

  note = 'all and (max-width: 1759px)', -nt
  laptop = 'all and (max-width: 1399px)', -lp
  tablet = 'all and (max-width: 1279px)', -tb
  pad = 'all and (max-width: 1023px)', -pd
  phone = 'all and (max-width: 767px)', -ph
*/


/*!
  FONTS
  ===============================================
*/
@font-face
  font-family: 'Raleway';
  src: local('Raleway Light'), local('Raleway-Light'),
    url('../fonts/hinted-Raleway-Light.woff2') format('woff2'),
    url('../fonts/hinted-Raleway-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;

@font-face
  font-family: 'Raleway';
  src: local('Raleway Italic'), local('Raleway-Italic'),
    url('../fonts/hinted-Raleway-Italic.woff2') format('woff2'),
    url('../fonts/hinted-Raleway-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;

@font-face
  font-family: 'Raleway';
  src: local('Raleway Bold'), local('Raleway-Bold'),
    url('../fonts/hinted-Raleway-Bold.woff2') format('woff2'),
    url('../fonts/hinted-Raleway-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;

@font-face
  font-family: 'Raleway';
  src: local('Raleway Regular'), local('Raleway-Regular'),
    url('../fonts/hinted-Raleway-Regular.woff2') format('woff2'),
    url('../fonts/hinted-Raleway-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;

@font-face
  font-family: 'Raleway';
  src: local('Raleway SemiBold'), local('Raleway-SemiBold'),
    url('../fonts/hinted-Raleway-SemiBold.woff2') format('woff2'),
    url('../fonts/hinted-Raleway-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;

@font-face
  font-family: 'Raleway';
  src: local('Raleway Medium'), local('Raleway-Medium'),
    url('../fonts/hinted-Raleway-Medium.woff2') format('woff2'),
    url('../fonts/hinted-Raleway-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;

@font-face
  font-family: 'Raleway';
  src: local('Raleway ExtraBold'), local('Raleway-ExtraBold'),
    url('../fonts/hinted-Raleway-ExtraBold.woff2') format('woff2'),
    url('../fonts/hinted-Raleway-ExtraBold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-display: swap;

@font-face
  font-family: 'Raleway';
  src: local('Raleway Italic'), local('Raleway-Italic'),
    url('../fonts/hinted-Raleway-Italic.woff2') format('woff2'),
    url('../fonts/hinted-Raleway-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
