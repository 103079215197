/*!
  UI
  ===============================================
*/

body
  font-family: $font;
  color: $colorText;


.container
  width: 100%;
  max-width: $container;
  padding-left: $containerPadding;
  padding-right: $containerPadding;
  margin: 0 auto;


.link
  transit();

  &:hover
    opacity: 0.6;


.is-process
  button[type="submit"]
    position: relative;

    &:before
      pseudo();
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: rgba(#fff,0.75);
      border-radius: 10px;
      z-index: 10;

    &:after
      pseudo();
      width: 32px;
      height: 32px;
      border-radius: 100%;
      top: 50%;
      left: 50%;
      margin-top: -16px;
      margin-left: -16px;
      border-width: 5px;
      border-color: #111827 #E5E7EB;
      border-style: solid;
      animation: process-rotate 1s ease-in-out infinite forwards;
      z-index: 15;

@keyframes process-rotate
  from
    transform: rotate(0deg);
  to
    transform: rotate(360deg);


@import 'framework';



/*!
  --- ELEMENTS BASIC ------------------
*/
.ui-icon
  size(16px,16px);
  flex: none;
  bg(center,contain);

  .ico
    size(100%,100%);


.ui-pic-bg
  position: absolute;
  size(100%,100%);
  top: 0;
  left: 0;

  img
    size(100%,100%);
    object-fit: cover;


.ui-pic-cover
  img
    size(100%,100%);
    object-fit: cover;


.ui-pic-contain
  img
    size(100%,100%);
    object-fit: contain;


.ui-media-ratio-1,
.ui-media-ratio-43,
.ui-media-ratio-169
  overflow: hidden;
  position: relative;
  width: 100%;

  img,
  iframe
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

.ui-media-ratio
  &-1
    padding-top: 100%;

  &-169
    padding-top: round((9 / 16) * 100%, 2);

  &-43
    padding-top: round((3 / 4) * 100%, 2);


.ui-tab
  display: none;
  opacity: 0;

  &.is-active
    display: block;
    animation: tab-show 1s linear 0.01s 1 forwards;

@keyframes tab-show
  from
    opacity: 0;
  to
    opacity: 1;


.ui-ul-clear
  padding: 0;
  margin: 0;
  list-style-type: none;



/*!
  --- FORM ----------------------------
*/
.ui-fg
  position: relative;

  .error-message
    position: absolute;
    bottom: 3px;
    right: 3px;
    font-size: 0.8em;
    color: $colorError;


.ui-select
  select
    appearance: none;

    &::-ms-expand
      display: none;



.ui-input
  height: 52px;
  border: 1px solid $colorBorder;
  border-radius: 8px;
  padding: 0 1rem;
  font-size: 15px;
  color: $colorText;

  &::placeholder
    color: $colorTextLight;

  &:hover
    border-color: #DDE3ED;

  &:focus
    border-color: $colorAccent;

  &[disabled],
  &.is-disabled
    color: $colorBorder;
    border-color: $colorBorder;

    &::placeholder
      color: inherit;


.ui-btn
  height: 52px;
  padding: 0 40px;
  font-weight: 600;
  background-color: $colorAccent;
  color: #fff;
  border-radius: 100px;
  font-size: 1rem;
  transit();

  &[role="button"]
    cursor: pointer;

  &:hover
    background-color: #26C36F;

  &:active
    background-color: #4CCE89;

  &[disabled],
  .is-disabled
    background-color: #EEF0F3;
    color: #C9CBD2;



/*!
  --- ELEMENTS ------------------------
*/

.ui-burger
  size(32px,24px);
  position: relative;

  &:before,
  &:after
    pseudo();

  &:before,
  &:after,
  span
    width: 100%;
    height: 4px;
    left: 0;
    background: red;
    border-radius: 10px;
    transit();

  &:before
    top: 0;

  &:after
    top: 100%;
    margin-top: -4px;

  span
    top: 50%;
    margin-top: 0;

  &.is-active
    span
      opacity: 0;

    &:before,
    &:after
      top: 50%;
      margin-top: -2px;

    &:before
      transform: rotate(45deg);

    &:after
      transform: rotate(-45deg);
